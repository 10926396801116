import { Component, ViewChild, AfterViewInit, OnInit, HostListener } from '@angular/core';
import { jqxGridComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxgrid';
import { getLocalization } from '../../../config/localization';
import { URL_SERVICIOS } from '../../../config/config';
import { DataService, FacturaService, AuthenticationService } from '../../../services/service.index';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-no-timbradas',
  templateUrl: './no-timbradas.component.html'
})
export class NoTimbradasComponent implements AfterViewInit, OnInit {
  // @ViewChild('myModal') myModal: NgxSmartModalService;
  //@ViewChild('content2') myModal: NgbModal;
  @ViewChild('infoDetalle') infoDetalle;
  @ViewChild('contentCancelar') contentCancelar;
  @ViewChild('myGrid') myGrid: jqxGridComponent;

  showpdf = false;
  srcpdf: any;
  usuario: any;
  motivoCancelacion: any;
  uuidSelect: any;
  cfdiSelect: any;
  constructor(
    private dataService: DataService,
    private _facturaService: FacturaService,
    private modalService: NgbModal,
    private _authService: AuthenticationService,
    private httpClient: HttpClient) { }

  ngOnInit() {
    this.usuario = this._authService.getUser();
  }
  ngAfterViewInit(): void {
    //this.getData();
    //this._smartModal.open('myModal', true);
    // this.ngxSmartModalService.getModal('myModal').open();
  }
  columns: any[] =
    [
      { text: 'Folio', datafield: 'folioCompleto', columntype: 'textbox', filtertype: 'input' },

      { text: 'Razon Social Receptor', datafield: 'razonSocialReceptor' },
      { text: 'Importe', datafield: 'total' },
      //{ text: 'Estatus', datafield: 'estatusSat' },
      { text: 'Observacion', datafield: 'observacionSat' },
      {
        text: "",
        datafield: 'opciones',
        width: 120,
        cellsalign: 'right',
        filterable: false,
        editable: false,
        sortable: false,
        createwidget: (row: any, column: any, value: string, htmlElement: HTMLElement): void => {
          //Boton Imprimir
          const buttonImprimir = document.createElement('button');
          buttonImprimir.id = 'imprimir' + row.bounddata.id;
          buttonImprimir.className = 'btn btn-info btn-sm mr-1 pull-right';
          buttonImprimir.innerHTML = "<i class='fa fa-eye'></i>";
          buttonImprimir.addEventListener('click', (event) => {
            this.imprimir(row.bounddata)
          });
          htmlElement.appendChild(buttonImprimir);
        }, initwidget: (row: number, column: any, value: any, htmlElement: HTMLElement): void => {
        }
      }
    ];
  source: any =
    {
      datatype: 'json',
      datafields: [
        { name: 'folioCompleto', type: 'string' },
        { name: 'razonSocialReceptor', type: 'string' },
        { name: 'total', type: 'string' },
        { name: 'estatusSat', type: 'string' },
        { name: 'observacionSat', type: 'string' },
        { name: 'uuid', type: 'string' },
        { name: 'id', type: 'string' }
      ],
      id: 'id',
      pagesize: 20,
      url: URL_SERVICIOS + 'factura/listado/notimbrada'
    };

  dataAdapter: any = new jqx.dataAdapter(this.source, {
    downloadComplete: (data, status, xhr) => {
      if (!this.source.totalrecords) {
        this.source.totalrecords = data.TotalRows;
      }

    }
  });
  rendergridrows(data) {
    return data.data;
  }
  imprimir(data: any) {
    this.cfdiSelect = data;
    //this.myModal.open('')
    this.modalService.open(this.infoDetalle, {
      size: "sm",
      centered: true,
      backdrop: false,
      keyboard: false,
      //closeOnOutsideClick: false,
      //backdropClass: "modal-backdrop"
    }).result.then((result) => {
      // this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      //this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  //this.myGrid.updatebounddata();
  onEvent(event: any): void {
    this.myGrid.updatebounddata();
  };

}
