import { Component, ViewChild, OnInit, HostListener } from '@angular/core';
import { jqxGridComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxgrid';
import { getLocalization } from '../../../config/localization';
import { URL_SERVICIOS } from '../../../config/config';
import { DataService } from '../../../services/data.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-listado-producto',
  templateUrl: './listado-producto.component.html'
})
export class ListadoProductoComponent implements OnInit {
  @ViewChild('myGrid') myGrid: jqxGridComponent;
  constructor(private dataService: DataService, private _router: Router, ) { }

  ngOnInit() {
  }
  columns: any[] =
    [
      { text: 'ID', datafield: 'id', columntype: 'textbox', filtertype: 'input' },
      { text: 'Descripcion', datafield: 'descripcion', columntype: 'textbox', filtertype: 'input' },
      { text: 'Aplica Iva', datafield: 'aplicaIva', width: 95 },
      { text: 'Precio', datafield: 'precio' },
      { text: 'Clave Producto', datafield: 'claveprodserv' },
      { text: 'Unidad Medida', datafield: 'unidadMedida' },
      { text: 'Clave Unidad', datafield: 'claveUnidad' },
      {
        text: "",
        datafield: 'opciones',
        width: 120,
        cellsalign: 'right',
        filterable: false,
        editable: false,
        sortable: false,
        createwidget: (row: any, column: any, value: string, htmlElement: HTMLElement): void => {
          const buttonEliminar = document.createElement('button');
          buttonEliminar.id = 'eliminar' + row.bounddata.id;
          buttonEliminar.className = 'btn btn-danger btn-sm mr-1 pull-right';
          buttonEliminar.innerHTML = "<i class='fa fa-trash'></i>";
          buttonEliminar.addEventListener('click', (event) => {
            this.cancelarFactura(row.bounddata)
          });
          htmlElement.appendChild(buttonEliminar);
          //Boton Editar.
          const buttonEditar = document.createElement('button');
          buttonEditar.id = 'editar' + row.bounddata.id;
          buttonEditar.className = 'btn btn-success btn-sm mr-1 ml-1 pull-right';
          buttonEditar.innerHTML = "<i class='fa fa-pencil'></i>";
          buttonEditar.addEventListener('click', (event) => {
            this._router.navigate(['producto/edicion/' + row.bounddata.id])
          });
          htmlElement.appendChild(buttonEditar);
        }, initwidget: (row: number, column: any, value: any, htmlElement: HTMLElement): void => {
        }
      }
    ];
  source: any =
    {
      datatype: 'json',
      datafields: [
        { name: 'descripcion', type: 'string' },
        { name: 'precio', type: 'string' },
        { name: 'claveprodserv', type: 'string' },
        { name: 'aplicaIva', type: 'string' },
        { name: 'claveUnidad', type: 'string' },
        { name: 'unidadMedida', type: 'string' },
        { name: 'id', type: 'string' }
      ],
      id: 'id',
      pagesize: 20,
      url: URL_SERVICIOS + 'producto/listado'
    };

  dataAdapter: any = new jqx.dataAdapter(this.source, {
    downloadComplete: (data, status, xhr) => {
      if (!this.source.totalrecords) {
        this.source.totalrecords = data.TotalRows;
      }
    }
  });
  rendergridrows(data) {
    return data.data;
  }
  cancelarFactura(uuid) {

  }
  onEvent(event: any): void {
    this.myGrid.updatebounddata();
  };
}