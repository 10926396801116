import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { URL_SERVICIOS } from '../config/config';
@Injectable({
    providedIn: 'root'
})
export class FacturaService {
    headers: HttpHeaders;
    constructor(
        private http: HttpClient
    ) { }

    post(data): Observable<any> {
        let url = URL_SERVICIOS + 'factura/almacenar';
        return this.http.post(url, data);
    };
    cancelar(data): Observable<any> {
        let url = URL_SERVICIOS + 'factura/cancelacion';
        return this.http.post(url, data);
    };
    enviarCorreo(data): Observable<any> {
        let url = URL_SERVICIOS + 'factura/enviarCorreo';
        return this.http.post(url, data);
    };
    imprimir(id: string) {
        return this.http
            .get(URL_SERVICIOS + 'factura/imprimir/' + id).pipe(
                map(response => response)
            );
    }
    obtener(id: string) {
        return this.http
            .get(URL_SERVICIOS + 'factura/obtener/' + id).pipe(
                map(response => response)
            );
    }
    obtenerDetalle(id: string) {
        return this.http
            .get(URL_SERVICIOS + 'factura/obtenerDetalle/' + id).pipe(
                map(response => response)
            );
    }
}
