import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-edicion-empresa',
  templateUrl: './edicion-empresa.component.html',
  styleUrls: ['./edicion-empresa.component.css']
})
export class EdicionEmpresaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
