import { Component, ViewChild, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService, ClienteService, ProductoService, Reciboe, FacturaService, AuthenticationService } from '../../../services/service.index';

@Component({
  selector: 'app-reciboepago',
  templateUrl: './reciboepago.component.html',
  styleUrls: ['./reciboepago.component.scss']
})
export class ReciboepagoComponent implements OnInit {
  Factura: any;
  constructor(private formBuilder: FormBuilder,
    private _clienteService: ClienteService,
    private _dataService: DataService,
    private _productoService: ProductoService,
    private _facturaService: FacturaService,
    private _authService: AuthenticationService,
    public router: Router,
    public activatedRoute: ActivatedRoute) { }

  ngOnInit() {

  }

}
