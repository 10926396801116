import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DataService, ClienteService, ProductoService, Invoce } from '../../../services/service.index';
import Swal from 'sweetalert2';
//import { ValidationService } from '@app/services/shared/validation.service';
@Component({
  selector: 'app-edicion-producto',
  templateUrl: './edicion-producto.component.html'
})
export class EdicionProductoComponent implements OnInit {
  frmProducto: any;
  constructor(
    public activatedRoute: ActivatedRoute,
    public _productoService: ProductoService,
    public router: Router) { }

  ngOnInit() {
    this.crearFormulario();
    this.activatedRoute.params.subscribe(params => {
      let id = params['id'];
      if (params['id'] !== 'nuevo') {
        Swal.showLoading();
        this._productoService.obtenerPorId(id).subscribe(data => {
          this.frmProducto.patchValue(data);
          Swal.hideLoading();
          Swal.close();
        });
      }
    });
  }
  crearFormulario() {
    this.frmProducto = new FormGroup({
      descripcion: new FormControl(null, Validators.required),
      precio: new FormControl(null, Validators.required),
      unidadMedida: new FormControl(null),
      claveprodserv: new FormControl(null, Validators.required),
      claveUnidad: new FormControl(null, Validators.required),
      tipoFactor: new FormControl('Exento', Validators.required),
      tasaOCuota:new FormControl(0, Validators.required),
      id: new FormControl(null)
    });
  }
  onSubmit() {
    //hacer calculo si aplica iva o no
    Swal.fire({
      title: 'Procesando ...',
      showLoaderOnConfirm: true,
      onOpen: (data) => {
        // console.log('Al abrir modal...');
        Swal.showLoading();
        this._productoService.almacenar(this.frmProducto.value)
          .subscribe((resp: any) => {
            if (!resp.response) {
              let msj = '';
              if (resp.errors) {
                for (let item of resp.errors) {
                  msj += `<br>${item}`;
                }
              }
              Swal.showValidationMessage(
                `<div class="alert alert-danger" role="alert">Error en el proceso: ${msj}</div>`
              );
            }
            Swal.hideLoading();
            Swal.fire({
              position: 'top-end',
              type: 'success',
              title: 'Producto registrado',
              showConfirmButton: false,
              timer: 1500
            });
            this.reset();
            return resp;
          },
            (err: any) => {
              Swal.hideLoading();
              Swal.close();
              Swal.showValidationMessage(
                `Error en el proceso: ${err}`
              )
            });

      },
      allowOutsideClick: () => !Swal.isLoading()
    });

  }
  reset() {
    this.frmProducto.reset();
    this.router.navigate(['/productos']);
  }
}
