import { Component, ViewChild, OnInit, HostListener } from '@angular/core';
import { jqxGridComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxgrid';
import { getLocalization } from '../../../config/localization';
import { URL_SERVICIOS } from '../../../config/config';
import { DataService } from '../../../services/data.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-listado-cliente',
  templateUrl: './listado-cliente.component.html'
})
export class ListadoClienteComponent implements OnInit {
  @ViewChild('myGrid') myGrid: jqxGridComponent;
  constructor(private dataService: DataService, private _router: Router, ) { }

  ngOnInit() {
  }
  columns: any[] =
    [
      { text: 'ID', datafield: 'id', columntype: 'textbox', filtertype: 'input' },
      { text: 'RazonSocial', datafield: 'razonSocial', columntype: 'textbox', filtertype: 'input' },
      { text: 'RFC', datafield: 'rfc', width: 130 },
      { text: 'CP', datafield: 'codigoPostal' },
      { text: 'Localidad', datafield: 'localidad' },
      { text: 'Municipio', datafield: 'municipio' },
      { text: 'Email', datafield: 'correo' },
      { text: 'V4', datafield: 'v4' },
      {
        text: "",
        datafield: 'opciones',
        width: 120,
        cellsalign: 'right',
        filterable: false,
        editable: false,
        sortable: false,
        createwidget: (row: any, column: any, value: string, htmlElement: HTMLElement): void => {
          const buttonEliminar = document.createElement('button');
          buttonEliminar.id = 'eliminar' + row.bounddata.id;
          buttonEliminar.className = 'btn btn-danger btn-sm mr-1 pull-right';
          buttonEliminar.innerHTML = "<i class='fa fa-trash'></i>";
          buttonEliminar.addEventListener('click', (event) => {
            this.eliminarCliente(row.bounddata)
          });
          htmlElement.appendChild(buttonEliminar);
          //Boton Editar.
          const buttonEditar = document.createElement('button');
          buttonEditar.id = 'editar' + row.bounddata.id;
          buttonEditar.className = 'btn btn-success btn-sm mr-1 ml-1 pull-right';
          buttonEditar.innerHTML = "<i class='fa fa-pencil'></i>";
          buttonEditar.addEventListener('click', (event) => {
            this._router.navigate(['cliente/edicion/' + row.bounddata.id])
          });
          htmlElement.appendChild(buttonEditar);
        }, initwidget: (row: number, column: any, value: any, htmlElement: HTMLElement): void => {
        }
      }
    ];
  /**/
  source: any =
    {
      datatype: 'json',
      datafields: [
        { name: 'razonSocial', type: 'string' },
        { name: 'rfc', type: 'string' },
        { name: 'codigoPostal', type: 'string' },
        { name: 'localidad', type: 'string' },
        { name: 'municipio', type: 'string' },
        { name: 'estado', type: 'string' },
        { name: 'correo', type: 'string' },
        { name: 'v4', type: 'string' },
        { name: 'id', type: 'string' }
      ],
      id: 'id',
      pagesize: 20,
      url: URL_SERVICIOS + 'cliente/listado'
    };

  dataAdapter: any = new jqx.dataAdapter(this.source, {
    downloadComplete: (data, status, xhr) => {
      if (!this.source.totalrecords) {
        this.source.totalrecords = data.TotalRows;
      }
    }
  });
  rendergridrows(data) {
    return data.data;
  }
  eliminarCliente(uuid) {

  }
  onEvent(event: any): void {
    this.myGrid.updatebounddata();
  };
}
