import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URL_SERVICIOS } from '../config/config';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ProductoService {
    totalProductores: number = 0;
    public url = URL_SERVICIOS + 'servicio';
    constructor(
        private http: HttpClient
    ) { }

    search(term: string) {
        if (term === '') {
            return of([]);
        }
        return this.http
            .get(this.url + '/buscar?busqueda=' + term).pipe(
                map(response => response)
            );
    }
    obtenerPorId(id: string) {
        if (id === null) {
            return of([]);
        }
        return this.http
            .get(URL_SERVICIOS + 'producto/obtener/' + id).pipe(
                map(response => response)
            );
    }

    almacenar(producto: any) {
        if (producto.id != null) {
            //actualizando
            let url = URL_SERVICIOS + 'producto/actualizar/' + producto.id;
            return this.http.put(url, producto)
                .pipe(
                    map((response: any) => response)
                );
        } else {
            // creando
            return this.http.post(URL_SERVICIOS + 'producto/registrar', producto)
                .pipe(
                    map((response: any) => response)
                );
        }
    }
}
