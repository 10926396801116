import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { URL_SERVICIOS } from '../config/config';
import { map } from 'rxjs/operators';
@Injectable({
    providedIn: 'root'
})
export class DataService {
    headers: HttpHeaders;
    constructor(
        private httpClient: HttpClient
    ) { }

    getData(recurso, paramas): Observable<any> {
        return this.httpClient.get(URL_SERVICIOS + recurso + paramas);
    };
    getFormasPago() {
        return this.httpClient
            .get(URL_SERVICIOS + 'metodopago').pipe(
                map((response: any) => response)
            );
    }
    getUsoCfdi() {
        return this.httpClient
            .get(URL_SERVICIOS + 'factura/usocfdi').pipe(
                map((response: any) => response)
            );
    }
    getAutocompletePropietario(paramas): Observable<any> {
        return this.httpClient
            .get(URL_SERVICIOS + 'api/propietario' + paramas).pipe(
                map((response: any) => {
                    return response.propietario;
                })
            );
    };

    sortData(sortOptions): Observable<any> {
        return this.httpClient.post(`${URL_SERVICIOS}/productor/listado`, sortOptions);
    };

    post(url, data): Observable<any> {
        return this.httpClient.post(`${URL_SERVICIOS}${url}`, data);
    };

    put(url, data): Observable<any> {
        return this.httpClient.put(`${URL_SERVICIOS}${url}`, data);
    };

    delete(url, data): Observable<any> {
        return this.httpClient.delete(`${URL_SERVICIOS}${url}`, { params: { status: data } });
    };

    get(recurso: any, query: any, join: any, sort: any, filterGroups: any, source: any): Observable<any> {
        let filtro = null;
        let ordenarpor = null;
        let filtroForeign = false;
        if (filterGroups) {
            var filterInfo = "";
            for (var x = 0; x < filterGroups.length; x++) {
                var filterDataField = filterGroups[x].datafield;
                var filterGroup = filterGroups[x].filter;
                //var filterOperator = filterGroup.operator;
                var filters = filterGroup.getfilters();
                /*let index = fields.indexOf(filterDataField);
                console.log(index);
                console.log(fields[index]);*/
                source.datafields.map(item => {
                    if (item.name === filterDataField) {
                        if (item.hasOwnProperty('map')) {
                            filterDataField = item.map;
                            filtroForeign = true;
                        }
                    }
                });
                //filterInfo += "\nData Field: " + filterDataField;
                //filterInfo += "\nOperator: " + filterOperator;

                for (var m = 0; m < filters.length; m++) {
                    var filter = filters[m];
                    var value = filter.value;
                    //var condition = filter.condition;
                    //var operator = filter.operator;
                    //var type = filter.type;
                    //filterInfo += "\nCondition " + m + ": " + condition;
                    //filterInfo += "\nValue " + m + ": " + value;
                    //console.log(filter.condition);
                    let comodin = '/';
                    if (filter.condition === 'EQUAL' && filter.type === 'booleanfilter') {
                        comodin = '|';
                    }
                    //if(filter.condition==='EMPTY' )
                    if (filtro && value) {
                        filtro += `,${filterDataField}:${comodin}${value}${comodin}`;
                    } else if (value) {
                        filtro = `q=${filterDataField}:${comodin}${value}${comodin}`;
                    }
                    //filterInfo += "\nFilter Type " + m + ": " + type;
                }
            }
        }
        if (sort) {
            if (sort.sortcolumn && sort.sortdirection.ascending) {
                ordenarpor = `sorting=${sort.sortcolumn}:asc`;
            } else if (sort.sortcolumn) {
                ordenarpor = `sorting=${sort.sortcolumn}:desc`;
            }
        }
        let endpoint = ''
        //verificar si hay filtro y que sea de un documento referenciado.
        if (filtro) {
            endpoint = `?${filtro}`;
        }

        if (query && endpoint) {
            endpoint += `&${query}`;
        } else if (query) {
            endpoint += `?${query}`;
        }

        if (join && endpoint) {
            endpoint += `&${join}`;
        } else if (join) {
            endpoint += `?${join}`;
        }

        if (ordenarpor && endpoint) {
            endpoint += `&${ordenarpor}`;
        } else if (ordenarpor) {
            endpoint += `?${ordenarpor}`;
        }
        //let url = `api/localidad/listado/${this.source.pagenum + 1}/${this.source.pagesize}`;
        if (filtroForeign) {
            //Usar pupulate de mongoose
            let url = URL_SERVICIOS + `api/${recurso}/listado/${source.pagenum + 1}/${source.pagesize}${endpoint}`;
            return this.httpClient.get(url).map((resp: any) => {
                return resp;
            });
        } else {
            //Usar los join.
            let url = URL_SERVICIOS + `api/${recurso}/listadoGeneral/${source.pagenum + 1}/${source.pagesize}${endpoint}`;

            return this.httpClient.get(url).map((resp: any) => {
                return resp;
            });
        }

    };

    getGrid(recurso: any, query: any, join: any, sort: any, filterGroups: any, source: any): Observable<any> {
        let filtro = '';
        let ordenarpor = '';
        let filtroForeign = false;
        let select = '';
        if (filterGroups) {
            var filterInfo = "";
            for (var x = 0; x < filterGroups.length; x++) {
                var filterDataField = filterGroups[x].datafield;
                var filterGroup = filterGroups[x].filter;
                //var filterOperator = filterGroup.operator;
                var filters = filterGroup.getfilters();
                source.datafields.map(item => {
                    if (item.name === filterDataField) {
                        if (item.hasOwnProperty('map')) {
                            filterDataField = item.map.replace('>', ".");
                        }
                    }
                });
                /*let index = fields.indexOf(filterDataField);
                console.log(index);
                console.log(fields[index]);*/
                /*source.datafields.map(item => {
                    filterDataField = item.name;
                    if (item.hasOwnProperty('map')) {
                        filterDataField = item.map.replace('>', ".");
                    }
                });*/
                //filterInfo += "\nData Field: " + filterDataField;
                //filterInfo += "\nOperator: " + filterOperator;

                for (var m = 0; m < filters.length; m++) {
                    var filter = filters[m];
                    console.log(filter);
                    var value = filter.value;
                    //var condition = filter.condition;
                    //var operator = filter.operator;
                    //var type = filter.type;
                    //filterInfo += "\nCondition " + m + ": " + condition;
                    //filterInfo += "\nValue " + m + ": " + value;
                    let comodin = '/';
                    if (filter.condition === 'EQUAL' && filter.type === 'booleanfilter') {
                        comodin = '|';
                        value = filter.value.toString();
                    }
                    //if(filter.condition==='EMPTY' )
                    if (filtro && value) {
                        filtro += `,${filterDataField}:${comodin}${value}${comodin}`;
                    } else if (value) {
                        filtro = `q=${filterDataField}:${comodin}${value}${comodin}`;
                    }
                    //filterInfo += "\nFilter Type " + m + ": " + type;
                }
            }
        }

        if (sort) {
            if (sort.sortcolumn && sort.sortdirection.ascending) {
                ordenarpor = `sorting=${sort.sortcolumn}:asc`;
            } else if (sort.sortcolumn) {
                ordenarpor = `sorting=${sort.sortcolumn}:desc`;
            }
        }
        //Crear select
        source.datafields.map(item => {
            let seleccionar = item.name;
            if (item.hasOwnProperty('map')) {
                seleccionar = item.map.replace('>', ".");
            }

            if (select) {
                select += `,${seleccionar}`;
            } else {
                select = `select=${seleccionar}`;
            }
        });

        let endpoint = ''
        //Verificar si existe select
        if (select != '') {
            endpoint = `?${select}`;
        }
        //verificar si hay filtro y que sea de un documento referenciado.
        if (filtro != '' && endpoint != '') {
            endpoint += `&${filtro}`;
        } else if (filtro != '') {
            endpoint += `?${filtro}`;
        }
        if (query != '' && endpoint != '') {
            endpoint += `&${query}`;
        } else if (query != '') {
            endpoint += `?${query}`;
        }

        if (join != '' && endpoint != '') {
            endpoint += `&${join}`;
        } else if (join != '') {
            endpoint += `?${join}`;
        }
        if (ordenarpor != '' && endpoint != '') {
            endpoint += `&${ordenarpor}`;
        } else if (ordenarpor != '') {
            endpoint += `?${ordenarpor}`;
        }

        let url = URL_SERVICIOS + `api/${recurso}/listadoGrid/${source.pagenum + 1}/${source.pagesize}${endpoint}`;

        /*return this.httpClient.get(url).map((resp: any) => {
            return resp;
        });*/
        return this.httpClient
            .get(url).pipe(
                map((response: any) => response)
            );
    };

    getRegimenFiscal(){
        return this.httpClient
            .get('https://app.misfacturas.mx/sat/cat_regimen').pipe(
                map((response: any) => response.data)
            );
    }
}
