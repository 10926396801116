import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthenticationService } from '../services/service.index';
import Swal from 'sweetalert2'

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService, private router: Router) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((err:any) => {
                console.log(err);
                const error = err.error.message || err.statusText;
                switch (err.status) {
                    case 400:
                        Swal.fire('Error...', error, 'warning')
                        break;
                    case 401:
                        console.log('Actualizar token.');
                        this.authenticationService.logout();
                        location.reload();
                        break;
                    case 403:
                        this.router.navigate(['/']);
                        break;
                    /*case 404:
                    Swal.showValidationMessage(`Error en el proceso: ${err}`)
                    break;*/
                    case 500:
                        Swal.fire('Oops...', 'Internal Server Error', 'error')
                        break;
                }
                return throwError(error);
            }))
    }
}