import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URL_SERVICIOS } from '../config/config';
//import { UsuarioService } from '../usuario/usuario.service';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
/*const PARAMS = new HttpParams({
    fromObject: {
        action: 'opensearch',
        format: 'json',
        origin: '*'
    }
});*/
//import swal from 'sweetalert';
@Injectable({ providedIn: 'root' })
export class ClienteService {
    totalProductores: number = 0;
    public url = URL_SERVICIOS + 'cliente';
    constructor(
        private http: HttpClient,
        //public _usuarioService: UsuarioService
    ) { }

    search(term: string) {
        if (term === '') {
            return of([]);
        }
        return this.http
            .get(this.url + '/buscar?busqueda=' + term).pipe(
                map(response => response)
            );
    }
    almacenar(data: any) {
        if (data.id != null) {
            //actualizando
            let url = URL_SERVICIOS + 'cliente/actualizar/' + data.id;
            return this.http.put(url, data)
                .pipe(
                    map((response: any) => response)
                );
        } else {
            // creando
            return this.http.post(URL_SERVICIOS + 'cliente', data)
                .pipe(
                    map((response: any) => response)
                );
        }
    }
    obtenerPorId(id: string) {
        if (id === null) {
            return of([]);
        }
        return this.http
            .get(URL_SERVICIOS + 'cliente/' + id).pipe(
                map(response => response)
            );
    }

    obtenerDetalle(id: number) {
        if (id === 0) {
            return of([]);
        }
        return this.http
            .get(this.url + '/detalle/' + id).pipe(
                map(response => response)
            );
    }

    obtenerPorRfc(rfc: string) {
        if (rfc === '') {
            return of([]);
        }
        return this.http
            .get(this.url + '/obtenerPorRfc/' + rfc).pipe(
                map(response => response)
            );
    }

}
