export class Reciboe {
    public metodoPago = 'PUE';
    public detalle = [];
    public total: any = 0;
    public subTotal: any = 0;
    public iva: any = 0;
    public ivaretenido: any = 0;
    public formaPago = '1';
    public usoCfdi = '3';
    public cliente: any;
    public sucursalId: any;
    public empresaId: any;
    public numCuenta: any;
    constructor() {
    }
    isFloat(n) {
        return n === +n && n !== (n | 0);
    }
    isInteger(n) {
        return n === +n && n === (n | 0);
    }
    round(number, precision) {
        var factor = Math.pow(10, precision);
        var tempNumber = number * factor;
        var roundedTempNumber = Math.round(tempNumber);
        return roundedTempNumber / factor;
    }
    calculoGlobal() {
        let sumaIva = 0;
        let sumaSubtotal = 0;
        for (let item of this.detalle) {
            sumaIva = sumaIva + item.iva;
            sumaSubtotal = sumaSubtotal + item.importe;
        }
        this.iva = this.round(sumaIva, 2);
        this.subTotal = this.round(sumaSubtotal, 2);
        let totalsumatoria = (this.subTotal + this.iva) - this.ivaretenido;
        //self.total = parseFloat(totalsumatoria).toFixed(2);
        this.total = this.round(totalsumatoria, 2);
    }
    recalcular() {
        let sumaIva = 0;
        let sumaSubtotal = 0;
        for (let item of this.detalle) {
            let importeItem = 0;
            //var calcImporte = parseFloat(item.precio) * parseFloat(item.cantidad);
            // item.importe = this.round(calcImporte, 6);

            if (item.aplicaIva === '1') {
                //Asignar precio y e importe
                item.precioNeto = this.round(item.precio / 1.16, 6);
                let calcImporte = parseFloat(item.precioNeto) * parseFloat(item.cantidad);
                item.importe = this.round(calcImporte, 6);
                //Calcular el iva por prdducto e incrementar al iva global.
                var calcIva = item.importe * 0.16;
                item.iva = this.round(calcIva, 6);
            } else {
                item.precioNeto = item.precio;
                let calcImporte = parseFloat(item.precio) * parseFloat(item.cantidad);
                item.importe = this.round(calcImporte, 6);
            }
            //Calcular importe por producto
            sumaIva = sumaIva + item.iva;
            sumaSubtotal = sumaSubtotal + item.importe;
        }

        this.iva = this.round(sumaIva, 2);
        this.subTotal = this.round(sumaSubtotal, 2);
        //self.impuesto = self.subTotal * parseFloat(self.impuestoIva);
        /*if(self.retieneIVA){
            self.ivaretenido   = self.monto * parseFloat(self.retencioniva);
        }*/
        //self.TotalImpuestosTrasladados=self.iva;
        let totalsumatoria = (this.subTotal + this.iva) - this.ivaretenido;
        //self.total = parseFloat(totalsumatoria).toFixed(2);
        this.total = this.round(totalsumatoria, 2);
    }

    agregar_detalle(producto, cantidad) {

        var nuevo = {
            //NoIdentificacion: agregar.valor,
            claveProdServ: producto.claveprodserv,
            claveUnidad: producto.claveUnidad,
            id: producto.id,
            unidad: producto.unidadMedida,
            cantidad: cantidad,
            descripcion: producto.descripcion,
            precio: producto.precio,
            precioNeto: 0,
            importe: 0,
            iva: 0,
            aplicaIva: producto.aplicaIva
        };
        this.detalle.push(nuevo);
        this.recalcular();
    }
    borrar_detalle(item) {
        var index = this.detalle.indexOf(item);
        this.detalle.splice(index, 1);
        this.calculoGlobal();
    }

}